import systemColors from 'styles/colors';

const colors = [
  '#3779D9',
  '#825DF1',
  '#9B7B31',
  '#25B980',
  '#3B7EAA',
  '#2AAAFF',
  '#D37347',
  '#F15D5F',
  '#AE418D',
  '#2E928F',
];

const getRandomGradient = () => {
  const randomColor1 = colors[Math.floor(Math.random() * colors.length)];
  const randomColor2 = colors[Math.floor(Math.random() * colors.length)];
  const degree = Math.floor(Math.random() * 360); // Random degree from 0 to 359
  return `linear-gradient(${degree}deg, ${randomColor1} 30%, ${randomColor2} 60%)`;
};

const getColorForName = (firstName, lastName = '', isGradient = false) => {
  const name = `${firstName}${lastName}`.trim().toLowerCase();
  if (!name) return colors[0];

  const hash = name.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const color = colors[hash % colors.length];

  return isGradient ? getRandomGradient() : color;
};

export const avatarStyles = (firstName, lastName, styles = {}) => ({
  marginRight: '16px',
  fontSize: '28px',
  lineHeight: '28px',
  fontWeight: 600,
  color: systemColors.text.primary,
  height: '54px',
  width: '54px',
  backgroundColor: getColorForName(firstName, lastName),
  ...styles,
});

export default getColorForName;

// backgroundColor: getColorForName(firstName, lastName)
// background: getColorForName(firstName, lastName, true)
